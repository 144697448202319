<template>
    <PageContainer>
        <Loader :isLoading="isLoading" />
        <h5>Media ( Click To Play )</h5>
        <div class="row">
            <div class="col-sm-6 col-md-4" v-for="media in medias" :key="media.id">
                <div class="youtube-container">
                    <h4>{{ media.title }}</h4>
                    <div
                        class="youtube-player"
                        :data-id="getVideoId(media.link, media.type)"
                        :data-type="media.type"
                        :data-thumbnail="imgsrc + media.image"
                    ></div>
                </div>
            </div>
        </div>
        <Paginator
            v-if="totalResults"
            :totalResults="totalResults"
            :page="page"
            :itemsPerPage="limit"
            @update="updatePage"
        />
    </PageContainer>
</template>

<script>
import { HTTP } from "../../_helper/http-constants"
import request from "../../apis/request"
import scrollTop from "../utils/scrollTop"
import PageContainer from "../utils/PageContainer.vue"
import Loader from "../Loader.vue"
import Paginator from "../utils/Paginator.vue"
import axios from "axios"

export default {
    data() {
        return {
            page: 1,
            limit: 6,
            totalResults: 0,
            medias: [],
            imgsrc: axios.defaults.baseURL + "/public",
            isLoading: false,
        }
    },
    components: {
        Paginator,
        PageContainer,
        Paginator,
        Loader,
    },
    watch: {
        async $route() {
            console.log("the route has updated for media")
            await this.initPage()
        },
    },
    async mounted() {
        scrollTop()
        console.log("mountd ataaaa")
        await this.initPage()
    },
    methods: {
        async initPage() {
            this.isLoading = true
            this.page = Number(this.$route.query.page) || 1
            console.log("the route for init page", this.page)
            try {
                await this.loadMedia()
                await this.loadCustomThumbnail()
            } catch (err) {}
            this.isLoading = false
        },
        getVideoId(link, type) {
            let id = link
            if (type === "vimeo" && link.includes("vimeo.com/")) {
                id = link.split("vimeo.com/")[1]
            } else if (link.includes("youtu.be")) {
                id = link.split("youtu.be/")[1]
            } else if (link.includes("?v=")) {
                id = link.split("?v=")[1]
            }
            return id
        },
        async loadCustomThumbnail() {
            /**
             * Get videos on load
             */
            ;(function() {
                getVideos()
            })()

            /**
             * For each video player, create custom thumbnail or
             * use Youtube max resolution default thumbnail and create
             * iframe video.
             */
            function getVideos() {
                var v = document.getElementsByClassName("youtube-player")
                for (var n = 0; n < v.length; n++) {
                    var p = document.createElement("div")
                    var id = v[n].getAttribute("data-id")

                    var placeholder = v[n].hasAttribute("data-thumbnail")
                        ? v[n].getAttribute("data-thumbnail")
                        : ""

                    if (placeholder.length) p.innerHTML = createCustomThumbail(placeholder)
                    else p.innerHTML = createThumbail(id)

                    v[n].appendChild(p)
                    p.addEventListener("click", function() {
                        var parent = this.parentNode
                        createIframe(
                            parent,
                            parent.getAttribute("data-id"),
                            parent.getAttribute("data-type")
                        )
                    })
                }
            }

            /**
             * Create custom thumbnail from data-attribute provided url
             * @param {string} url
             * @return {string} The HTML containing the <img> tag
             */
            function createCustomThumbail(url) {
                return (
                    '<img class="img-fluid youtube-thumbnail" style="height: 300px" src="' +
                    url +
                    '" alt="Youtube Preview" /><div class="youtube-play-btn"></div>'
                )
            }

            /**
             * Get Youtube default max resolution thumbnail
             * @param {string} id The Youtube video id
             * @return {string} The HTML containing the <img> tag
             */
            function createThumbail(id) {
                return (
                    '<img class="youtube-thumbnail" src="//i.ytimg.com/vi_webp/' +
                    id +
                    '/maxresdefault.webp" alt="Youtube Preview"><div class="youtube-play-btn"></div>'
                )
            }

            /**
             * Create and load iframe in Youtube container
             **/
            function createIframe(v, id, type = "youtube") {
                var iframe = document.createElement("iframe")
                console.log(v)
                iframe.style.width = "100%"
                iframe.style.height = "300px"
                if (type === "youtube") {
                    iframe.setAttribute(
                        "src",
                        "//www.youtube.com/embed/" +
                            id +
                            "?autoplay=1&color=white&autohide=2&modestbranding=1&border=0&wmode=opaque&enablejsapi=1&showinfo=0&rel=0"
                    )
                } else {
                    iframe.setAttribute("src", "https://player.vimeo.com/video/" + id)
                }
                iframe.setAttribute("frameborder", "0")
                iframe.setAttribute("class", "youtube-iframe")
                v.firstChild.replaceWith(iframe)
            }

            /** Pause video on modal close **/
            document.querySelector("#video-modal").on("hidden.bs.modal", function(e) {
                e.target.find("iframe").remove()
            })

            /** Pause video on modal close **/
            document.querySelector("#video-modal").on("show.bs.modal", function(e) {
                getVideos()
            })
        },
        async loadMedia() {
            try {
                const res = await request.get(`/media?page=${this.page}&limit=${this.limit}`)
                this.medias = res.data.result
                this.totalResults = res.data.total_results
            } catch (err) {
                console.log("error in fetching media", err)
            }
        },
        async updatePage(data) {
            this.page = data.page
        },
    },
}
</script>

<style lang="scss" scoped>
.youtube-container {
    display: block;
    width: 100%;
    max-width: 600px;
    margin: 30px auto;
}

.youtube-player {
    display: block;
    margin: 20px auto;

    overflow: hidden;
    position: relative;
    width: 100%;
    // height: 100%;
    cursor: hand;
    cursor: pointer;
    display: block;
}

img.youtube-thumbnail {
    bottom: 0;
    display: block;
    left: 0;
    margin: auto;
    max-width: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    height: auto;
}
div.youtube-player:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.6);
}

div.youtube-play-btn {
    height: 72px;
    width: 72px;
    left: 50%;
    top: 50%;
    margin-left: -36px;
    margin-top: -36px;
    position: absolute;
    background: url("https://freepngimg.com/thumb/categories/1398.png") no-repeat center center;
    background-size: 72px 72px;
}

.youtube-iframe {
    // width: 100%;
    // height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
</style>
