<template>
    <div class="my-4 mb-5 container"><slot></slot></div>
</template>

<script>
import scrollTop from "./scrollTop"
export default {
    mounted() {
        scrollTop()
    },
}
</script>

<style></style>
